import "styles/pages/home-page.scss";

import React, { useState } from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import HeaderWithOptions from "../components/HeaderWithOptions";
import CompareContent from "../page_components/compare/Content";

const Compare = ({ data }) => {
  const [selected_option, setSelectedOption] = useState(0);

  const all_investments = data?.allWpInvestment?.nodes;
  const all_apartments = data?.allWpApartament?.nodes;

  return (
    <Layout
      location="Porównaj"
      blue_header
      seo={{ title: "Porównaj apartamenty" }}
    >
      <HeaderWithOptions
        title="Porównanie"
        sub_title="oferty apartamentów"
        selected_option_text="Wybierz opcję porównania"
        selected_option={selected_option}
        setSelectedOption={setSelectedOption}
      />
      <CompareContent
        all_investments={all_investments}
        all_apartments={all_apartments}
        selected_view={selected_option}
      />
    </Layout>
  );
};

export default Compare;

export const query = graphql`
  query apartmentsToCompare {
    allWpApartament {
      nodes {
        id
        apartments {
          blueprintFile {
            sourceUrl
            localFile {
              publicURL
            }
          }
          investment {
            ... on WpInvestment {
              id
              investments {
                mainImg {
                  sourceUrl
                  altText
                }
                mainTitle
                subtitle
                contactLength
                deadline
                backPercentage
                shortLocation
                vacationsDaysCustom
                subdivisionModel
                smallInfoInterior
                currency
              }
            }
          }
          compareForStart
          mainTitle
          surface
          price
        }
      }
    }
    allWpInvestment {
      nodes {
        id
        slug
        uri
        investments {
          compareForStart
          shortLocation
          mainTitle
          deadline
          backPercentage
          subtitle
          contactLength
          vacationsDaysCustom
          surfacemin
          surfacemax
          priceMax
          priceMin
          currency
          subdivisionModel
          smallInfoInterior
          mainImg {
            sourceUrl
          }
        }
      }
    }
  }
`;
