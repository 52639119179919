import "./styles.scss";

import React from "react";
import classnames from "classnames";

import BuildingIcon from "icons/facilities/building.svg";
import SofaIcon from "icons/facilities/sofa.svg";

const mainClass = "header-with-options";

const options_data = [
  {
    icon: <BuildingIcon />,
    label: "Inwestycje",
  },
  {
    icon: <SofaIcon />,
    label: "Apartamenty",
  },
];

const HeaderWithOptions = ({
  title,
  sub_title,
  icon,
  selected_option_text,
  selected_option,
  setSelectedOption,
}) => {
  return (
    <div
      className={classnames(mainClass, {
        [`${mainClass}--vertical-mode`]: !!sub_title,
      })}
    >
      <div className="container">
        <h1>
          {icon} {title}
          {!!sub_title && <span>{sub_title}</span>}
        </h1>
        <div className={`${mainClass}__options`}>
          <span>{selected_option_text}</span>
          <div className={`${mainClass}__options__items`}>
            {options_data?.map(({ icon, label }, index) => (
              <button
                key={index}
                className={classnames(`${mainClass}__options__items__item`, {
                  [`${mainClass}__options__items__item--active`]:
                    selected_option === index,
                })}
                onClick={() => setSelectedOption(index)}
              >
                {!!icon && icon} {label}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderWithOptions;
