import "./styles.scss";

import React from "react";
import Button from "components/Button";

import CompareSlider from "../Slider";

import { useCompareInvestments } from "context/compare_investments";
import { useCompareApartments } from "context/compare_apartments";
import { Link } from "gatsby";

const mainClass = "compare-content";

const ComparePlaceholder = () => {
  return (
    <div className={`${mainClass}__placeholder`}>
      <h2>Dodaj inwestycje do porównania</h2>
      <Link to="/investments/">
        <Button>Wyszukiwarka</Button>
      </Link>
    </div>
  );
};

const CompareContent = ({ all_investments, all_apartments, selected_view }) => {
  const { compare_investments, addToCompare } = useCompareInvestments();
  const { compare_apartments, addToCompare: addToApartmentsCompare } =
    useCompareApartments();

  return (
    <div className={mainClass}>
      <div className="container">
        {selected_view === 0 ? (
          compare_investments.length !== 0 ? (
            <CompareSlider
              all_investments={all_investments}
              all_apartments={all_apartments}
              selected_view={selected_view}
            />
          ) : (
            <ComparePlaceholder />
          )
        ) : compare_apartments.length !== 0 ? (
          <CompareSlider
            all_investments={all_investments}
            all_apartments={all_apartments}
            selected_view={selected_view}
          />
        ) : (
          <ComparePlaceholder />
        )}
      </div>
    </div>
  );
};

export default CompareContent;
