const get_prefix_currency = (currency) => {
  switch (currency) {
    case "euro":
      return `€`;
    default:
      return `zł`;
  }
};

const priceFormat = (price, currency, type = "apartament") => {
  const formatted_price = price
    ?.toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  const suffix = `${get_prefix_currency(currency)}${
    type === "meter" ? "/m²" : ""
  }`;

  return { price: formatted_price, suffix };
};

export default priceFormat;
