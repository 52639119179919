import "./styles.scss";
import React from "react";
import Slider from "react-slick";

import { useCompareInvestments } from "context/compare_investments";
import { useCompareApartments } from "context/compare_apartments";
import priceFormat from "utils/priceFormat";

import ArrowIcon from "icons/slider-arrow.svg";
import CondoPinIcon from "icons/condo-pin.svg";
import ExitIcon from "icons/exit-modal.svg";
import { Link } from "gatsby";

const mainClass = "compare-slider";

const compare_properties = [
  "Lokalizacja",
  "Nazwa inwestycji",
  "Oddanie inwestycji",
  "Stopa zwrotu:",
  "Długość umowy",
  "Pobyt właścicielski",
  "Powierzchnia",
  "Zakres cen",
  "Wyposażenie i wykończenie w cenie:",
];

// const vacation_days_variant = (value) => {
//   switch (value) {
//     case "none":
//       return "Brak";
//     case "other":
//       return "Inne";
//     default:
//       return `${value} dni`;
//   }
// };

function CustomArrow(props) {
  const { className, onClick } = props;
  return <ArrowIcon className={className} onClick={onClick} />;
}

const CompareSlider = ({ all_investments, all_apartments, selected_view }) => {
  const { compare_investments, addToCompare } = useCompareInvestments();
  const { compare_apartments, addToCompare: addToApartmentsCompare } =
    useCompareApartments();

  const categorySlider = {
    dots: false,
    infinite:
      selected_view === 0
        ? compare_investments?.length >= 3
        : compare_apartments?.length >= 3,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    nextArrow: (
      <CustomArrow
        className={`${mainClass}__arrow ${mainClass}__arrow--next`}
      />
    ),
    prevArrow: (
      <CustomArrow
        className={`${mainClass}__arrow ${mainClass}__arrow--prev`}
      />
    ),
    responsive: [
      {
        breakpoint: 1010,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 830,
        settings: "unslick",
      },
    ],
  };

  const filttered_investments = all_investments
    ?.filter((item) => compare_investments?.includes(item?.id))
    ?.map(({ id, uri, investments }) => {
      const price_min_formatted = priceFormat(
        investments?.priceMin,
        investments?.currency
      );
      const price_max_formatted = priceFormat(
        investments?.priceMax,
        investments?.currency
      );

      return {
        id,
        uri,
        image: investments?.mainImg?.sourceUrl,
        location: investments?.shortLocation,
        title: investments?.mainTitle,
        items: [
          investments?.deadline,
          !!investments?.backPercentage
            ? `${investments?.backPercentage} %`
            : "Model podziałowy",
          `${investments?.contactLength} lat`,
          investments?.vacationsDaysCustom,
          `${investments?.surfacemin} - ${investments?.surfacemax} m2`,
          `${price_min_formatted?.price} - ${price_max_formatted?.price} ${price_max_formatted?.suffix}`,
          !!investments?.smallInfoInterior ? "Tak" : "Nie",
        ],
      };
    });

  const filttered_apartments = all_apartments
    ?.filter((item) => compare_apartments?.includes(item?.id))
    ?.map(({ id, apartments }) => {
      const price_formatted = priceFormat(
        apartments?.price,
        apartments?.investment[0]?.currency
      );

      return {
        id,
        location: apartments?.investment[0]?.investments?.shortLocation,
        title: apartments?.mainTitle,
        image: apartments?.investment[0]?.investments?.mainImg?.sourceUrl,
        items: [
          apartments?.investment[0]?.investments?.deadline,
          apartments?.investment[0]?.investments?.backPercentage,
          apartments?.investment[0]?.investments?.contactLength,
          apartments?.investment[0]?.investments?.vacationsDaysCustom,
          `${apartments?.surface} m2`,
          // `${price_formatted?.price} ${price_formatted?.suffix}`,
          `-`,
          !!apartments?.investment[0]?.investments?.smallInfoInterior
            ? "Tak"
            : "Nie",
        ],
      };
    });

  const data_to_show =
    selected_view === 0 ? filttered_investments : filttered_apartments;

  return (
    <div className={mainClass}>
      <div className={`${mainClass}__aside`}>
        {compare_properties?.map((item, index) => (
          <div key={index} className={`${mainClass}__aside__item`}>
            <span>{item}</span>
          </div>
        ))}
      </div>
      <div className={`${mainClass}__content`}>
        <Slider {...categorySlider}>
          {data_to_show?.map((item, index) => (
            <div key={index}>
              <div className={`${mainClass}__content__item`}>
                <div className={`${mainClass}__content__item__header`}>
                  {!!item?.uri ? (
                    <Link to={item?.uri}>
                      <img src={item?.image} alt="" />
                    </Link>
                  ) : (
                    <img src={item?.image} alt="" />
                  )}

                  <span>
                    <CondoPinIcon />
                    {item?.location}
                  </span>
                  {!!item?.uri ? (
                    <Link to={item?.uri}>
                      <h2>{item?.title}</h2>
                    </Link>
                  ) : (
                    <h2>{item?.title}</h2>
                  )}
                </div>
                <div className={`${mainClass}__content__item__list`}>
                  {item?.items?.map((item) => (
                    <div className={`${mainClass}__content__item__list__item`}>
                      <span>{item}</span>
                    </div>
                  ))}
                </div>
                <div className={`${mainClass}__content__item__action`}>
                  <button
                    onClick={() =>
                      selected_view === 0
                        ? addToCompare(item?.id)
                        : addToApartmentsCompare(item?.id)
                    }
                  >
                    Usuń
                    <ExitIcon />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default CompareSlider;
